import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Content from '../Content'

const TextPageTemplate = ({ meta_title, meta_description, title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div className='flex flex-col bg-white'>
      <div className='container px-8 mx-auto'>
        <Helmet>
          <title>{meta_title}</title>
          <meta name='description' content={meta_description} />
        </Helmet>

        <main className='flex flex-wrap items-center py-12 my-12 text-center md:text-left lg:px-24'>
          <div className='w-full whitespace-pre-line md:w-full'>
            <h1 className='mb-6 text-5xl leading-tight text-black'>
              {title}
            </h1>
          </div>
        </main>

        <h2 className='has-text-weight-semibold is-size-2'>
          {}
        </h2>
        <PageContent className='mb-12 markdown' content={content} />
      </div>
    </div>
  )
}

TextPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default TextPageTemplate
